import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Categoria from '~/components/Categoria';
import ContaBanco from '~/components/ContaBanco';
import Finalizadora from '~/components/Finalizadora';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import {
  InputNumber2 as InputNumber,
  InputPercent,
} from '~/components/NovosInputs';
import Separator from '~/components/Separator';
import ToggleDefault from '~/components/ToggleDefault';
import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';
import api from '~/services/api';
import { errorHandler } from '~/utils/ErrorHandler';
import { BuscaFornecedor } from '../../components/BuscaFornecedor';
import {
  Condicao,
  IInterface,
  ISelect,
  tipoIntegraFrente,
  tipoRegime,
  tipoRegimeFCP,
} from '../../types';
import { convertToNumber } from '../../utils/convertToNumber';

const Geral = () => {
  const {
    formRegraLoja,
    changeFormRegraLoja,
    initInicializado,
    setFormRegraLoja,
    isUpdate,
    formTela: {
      register,
      setValue,
      control,
      formState: { errors },
      clearErrors,
      getValues,
    },
  } = useRegraLoja();
  const [interfacePDV, setInterfacePdvs] = useState<ISelect[]>([]);
  const [interfacePDVAtiva, setInterfacePdvsAtiva] = useState<ISelect[]>([]);
  const [condicoes, setCondicoes] = React.useState<ISelect[]>([]);
  const disabled = true;
  useEffect(() => {
    // busca as interfaces de PDV
    api.get('/interface/0').then((res) => {
      const { data, success } = res.data;
      if (success) {
        const interfaces = data.map((inter: IInterface) => {
          return {
            value: inter.cod_interface,
            label: inter.des_interface,
          };
        });
        setInterfacePdvs(interfaces);
        setValue('interfaces', interfaces);

        const interfaceAtiva = data
          .filter((d: any) => d.flg_inativo !== true)
          .map((i: IInterface) => ({
            value: i.cod_interface,
            label: i.des_interface,
          }));

        setInterfacePdvsAtiva(interfaceAtiva);
      }
    });
    getCondicoes();
  }, []);

  const getCondicoes = async () => {
    try {
      const res = await api.get('/condicao');
      const { success, data, message } = res.data;
      if (!success) throw new Error(message);
      const options: ISelect[] = data.map((condicao: Condicao) => ({
        label: `${condicao.des_condicao} - ${condicao.des_definicao}`,
        value: condicao.cod_condicao,
      }));
      setCondicoes(options);
    } catch (error: any) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    getCondicoes();
  }, []);

  async function AlertIncidePISCofins() {
    await Swal.fire({
      text: 'Alterar o regime para Optante do Simples implica na necessidade de informar o CSON no cadastro de tributação',
      icon: 'warning',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ESTOU CIENTE',
      cancelButtonText: 'Não',
    });
  }

  return (
    <>
      <Row>
        <Col sm="12">
          <Separator labelText="Imposto" />
        </Col>
        <Col md="3">
          <InputSelect
            options={tipoRegime}
            label="Regime Tributário *"
            noOptionsMessage="Nenhum registro encontrado"
            value={
              tipoRegime.find((item: ISelect) => {
                return item.value === formRegraLoja.tipo_regime.value.value;
              }) ?? {
                value: undefined,
                label: 'Selecione',
              }
            }
            isRequired
            setInvalid={!!errors.tipo_regime}
            iniInicializado={!!errors.tipo_regime}
            onChange={(value: any, isInvalid = true) => {
              setValue('tipo_regime', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                tipo_regime: {
                  ...formRegraLoja.tipo_regime,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
              if (value.label === 'Optante Simples') AlertIncidePISCofins();
            }}
            openMenuOnFocus={
              formRegraLoja.tipo_regime.value.label !== 'Optante Simples'
            }
          />
        </Col>

        <div className="col-sm-12 col-md-3">
          <InputSelect
            options={tipoRegimeFCP}
            noOptionsMessage="Nenhum registro encontrado"
            label="Regime FCP *"
            value={
              tipoRegimeFCP.find((item: ISelect) => {
                return item.value === formRegraLoja.tipo_regime_fcp.value.value;
              }) ?? {
                value: undefined,
                label: 'Selecione',
              }
            }
            isRequired
            setInvalid={!!errors.tipo_regime_fcp}
            iniInicializado={!!errors.tipo_regime_fcp}
            onChange={(value: any, isInvalid = true) => {
              setValue('tipo_regime_fcp', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                tipo_regime_fcp: {
                  ...formRegraLoja.tipo_regime_fcp,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>

        <div className="col-sm-12 col-md-3">
          <InputPercent
            label="Despesas Operacionais *"
            min={0}
            max={100}
            name="val_desp_operacional"
            placeholder="0,00"
            step=".01"
            isError={!!errors.val_desp_operacional}
            register={register}
            control={control}
          />
        </div>

        <div className="col-sm-12 col-md-3">
          <ToggleDefault
            labelText="Trunca Valores de Débito ICMS?"
            setChecked={formRegraLoja.flg_trunca_val_deb_icms.value}
            onSwitch={() => {
              setValue(
                'flg_trunca_val_deb_icms',
                !formRegraLoja.flg_trunca_val_deb_icms.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_trunca_val_deb_icms: {
                  ...formRegraLoja.flg_trunca_val_deb_icms,
                  value: !formRegraLoja.flg_trunca_val_deb_icms.value,
                },
              });
            }}
          />
        </div>

        {/* <div className="col-sm-12 col-md-12 mt-2">
          <ToggleDefault
            labelText="Ignora Impostos de ST do Conhecimento de Frete no Custo da Mercadoria?"
            setChecked={formRegraLoja.flg_ignora_st_cf_custo.value}
            onSwitch={() => {
              setValue(
                'flg_ignora_st_cf_custo',
                !formRegraLoja.flg_ignora_st_cf_custo.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_ignora_st_cf_custo: {
                  ...formRegraLoja.flg_ignora_st_cf_custo,
                  value: !formRegraLoja.flg_ignora_st_cf_custo.value,
                },
              });
            }}
          />
        </div> */}
      </Row>

      <Row>
        <Col sm="12">
          <Separator labelText="GARE" />
        </Col>
        <Col md="6">
          <BuscaFornecedor
            value={formRegraLoja.cod_pessoa_sefaz.value}
            label="Fornecedor SEFAZ"
            init={initInicializado}
            required={formRegraLoja.cod_pessoa_sefaz.isRequired}
            invalid={!!errors.cod_pessoa_sefaz}
            onChange={(data) => {
              setValue('cod_pessoa_sefaz', data.cod_fornecedor);

              setFormRegraLoja((prev) => {
                return {
                  ...prev,
                  cod_pessoa_sefaz: {
                    ...prev.cod_pessoa_sefaz,
                    value: data.cod_fornecedor,
                  },
                  cod_cc_gare: {
                    ...prev.cod_cc_gare,
                    isRequired: true,
                    isInvalid: !prev.cod_cc_gare.value?.value,
                  },
                  cod_finalizadora_gare: {
                    ...prev.cod_finalizadora_gare,
                    isRequired: true,
                    isInvalid: !prev.cod_finalizadora_gare.value?.value,
                  },
                  num_condicao_gare: {
                    ...prev.num_condicao_gare,
                    isRequired: true,
                    isInvalid: !prev.num_condicao_gare.value,
                  },
                  cod_condicao_gare: {
                    ...prev.cod_condicao_gare,
                    isRequired: true,
                    isInvalid: !prev.cod_condicao_gare.value?.value,
                  },
                  cod_categoria_gare: {
                    ...prev.cod_categoria_gare,
                    isRequired: true,
                    isInvalid: !prev.cod_categoria_gare.value?.value,
                  },
                };
              });
            }}
          />
        </Col>
        <Col md="6">
          <ContaBanco
            value={formRegraLoja.cod_cc_gare.value}
            loja={formRegraLoja.cod_loja.value.value}
            iniInicializado={!!errors.cod_cc_gare}
            isRequired
            setInvalid={!!errors.cod_cc_gare}
            onChange={(value: any, isInvalid = true) => {
              setValue('cod_cc_gare', value);
              setFormRegraLoja((prev) => {
                return {
                  ...prev,
                  cod_cc_gare: {
                    ...prev.cod_cc_gare,
                    value,
                    isInvalid: value === undefined,
                  },
                };
              });
            }}
          />
        </Col>
        <Col md={4}>
          <Finalizadora
            value={formRegraLoja.cod_finalizadora_gare.value}
            loja={formRegraLoja.cod_loja.value.value}
            initialized={!!errors.cod_finalizadora_gare}
            isRequired
            setInvalid={!!errors.cod_finalizadora_gare}
            onChange={(value: any, isInvalid = true) => {
              setValue('cod_finalizadora_gare', value);
              setValue('num_condicao_gare', value.num_condicao);
              setValue('cod_condicao_gare', {
                value: value.cod_condicao,
                label: '',
              });
              setFormRegraLoja((prev) => {
                return {
                  ...prev,
                  cod_finalizadora_gare: {
                    ...prev.cod_finalizadora_gare,
                    value,
                    isInvalid: value === undefined,
                  },
                  num_condicao_gare: {
                    ...prev.num_condicao_gare,
                    value: value.num_condicao,
                    isInvalid: Number.isNaN(value.num_condicao),
                  },
                  cod_condicao_gare: {
                    ...prev.cod_condicao_gare,
                    value: { value: value.cod_condicao, label: '' },
                    isInvalid: !value.cod_condicao,
                  },
                };
              });
            }}
          />
        </Col>
        <Col sm={12} md={1}>
          <InputNumber
            label="Condição"
            name="num_condicao_gare"
            placeholder="0"
            register={register}
            control={control}
            min={-999}
            max={999}
            maxLength={3}
            isError={!!errors.num_condicao_gare}
            value={`${formRegraLoja.num_condicao_gare.value ?? undefined}`}
            onBlur={(event: any) => {
              if (event.target.value === '') {
                setFormRegraLoja((prev) => {
                  return {
                    ...prev,
                    num_condicao_gare: {
                      ...prev.num_condicao_gare,
                      value: 0,
                      isInvalid: Number.isNaN(event.target.value),
                    },
                  };
                });
              }
            }}
            onInput={(ev: any) => {
              const { value } = ev.target;
              clearErrors('num_condicao_gare');

              if (value.toString().includes('-')) {
                setFormRegraLoja((prev) => {
                  return {
                    ...prev,
                    num_condicao_gare: {
                      ...prev.num_condicao_gare,
                      value: 0,
                    },
                  };
                });
              } else if (value.toString().length <= 3) {
                setFormRegraLoja((prev) => {
                  return {
                    ...prev,
                    num_condicao_gare: {
                      ...prev.num_condicao_gare,
                      value,
                      isInvalid: Number.isNaN(value),
                    },
                  };
                });
              }
            }}
          />
        </Col>
        <Col sm={12} md={3}>
          <InputSelect
            label="Tipo de Condição"
            noOptionsMessage="Nenhum registro encontrado"
            value={
              condicoes.find((condicao: ISelect) => {
                return (
                  condicao.value ===
                  formRegraLoja.cod_condicao_gare.value?.value
                );
              }) ?? null
            }
            placeholder="Informe o Tipo"
            options={condicoes}
            iniInicializado={!!errors.cod_condicao_gare}
            isRequired
            setInvalid={!!errors.cod_condicao_gare}
            onChange={(value: any, isInvalid = true) => {
              setValue('cod_condicao_gare', value);
              setFormRegraLoja((prev) => {
                return {
                  ...prev,
                  cod_condicao_gare: {
                    ...prev.cod_condicao_gare,
                    value,
                    isInvalid: !value.value,
                  },
                };
              });
            }}
          />
        </Col>
        <Col sm={12} md={4}>
          <Categoria
            value={formRegraLoja.cod_categoria_gare.value}
            iniInicializado={!!errors.cod_categoria_gare}
            isRequired
            setInvalid={!!errors.cod_categoria_gare}
            onChange={(value: any, isInvalid = true) => {
              setValue('cod_categoria_gare', value);
              setFormRegraLoja((prev) => {
                return {
                  ...prev,
                  cod_categoria_gare: {
                    ...prev.cod_categoria_gare,
                    value,
                    isInvalid: !value.value,
                  },
                };
              });
            }}
          />
        </Col>
      </Row>
      {!disabled && (
        <Row>
          <Col sm="12">
            <Separator labelText="Preço" />
          </Col>
          <Col md="3">
            <InputPercent
              label="Margem Mínima"
              min={0}
              max={100}
              name="val_margem_venda"
              placeholder="0,00"
              step=".01"
              isError={!!errors.val_margem_venda}
              register={register}
              control={control}
            />
          </Col>

          <div className="col-sm-12 col-md-3">
            <InputNumber
              label="Meses Cálculo Venda Média"
              placeholder="0"
              name="num_meses_vm"
              register={register}
              control={control}
              min={0}
              max={12}
              maxLength={2}
              isError={!!errors.num_meses_vm}
              value={`${formRegraLoja.num_meses_vm.value ?? 0}`}
              onInput={(ev: any) => {
                const { value } = ev.target;
                const trimmedValue = value.toString().trim().slice(0, 2);

                if (trimmedValue.includes('-')) {
                  setValue('num_meses_vm', 0);
                  changeFormRegraLoja({
                    ...formRegraLoja,
                    num_meses_vm: {
                      ...formRegraLoja.num_meses_vm,
                      value: 0,
                    },
                  });
                } else if (trimmedValue <= 12 || Number.isNaN(trimmedValue)) {
                  setValue('num_meses_vm', trimmedValue);
                  changeFormRegraLoja({
                    ...formRegraLoja,
                    num_meses_vm: {
                      ...formRegraLoja.num_meses_vm,
                      value: trimmedValue,
                      isInvalid: Number.isNaN(trimmedValue),
                    },
                  });
                }
              }}
            />
          </div>
        </Row>
      )}
      {/* <Row>
        <Col sm="12">
          <Separator labelText="PDV" />
        </Col>
        <Col md="3">
          <InputSelect
            options={tipoIntegraFrente}
            noOptionsMessage="Nenhum registro encontrado"
            label="Frente de Loja *"
            value={
              tipoIntegraFrente.find((item: ISelect) => {
                return (
                  item.value === formRegraLoja.tipo_integra_frente.value.value
                );
              }) ?? {
                value: undefined,
                label: 'Selecione',
              }
            }
            isRequired
            setInvalid={!!errors.tipo_integra_frente}
            iniInicializado={!!errors.tipo_integra_frente}
            onChange={(value: any, isInvalid = true) => {
              setValue('tipo_integra_frente', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                tipo_integra_frente: {
                  ...formRegraLoja.tipo_integra_frente,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </Col>

        <div className="col-sm2 col-md-3">
          <InputSelect
            options={interfacePDVAtiva}
            noOptionsMessage="Nenhum registro encontrado"
            label="Interface *"
            value={
              interfacePDV.find((item: ISelect) => {
                return (
                  item.value ===
                  formRegraLoja.cod_interface_carga_pdv.value.value
                );
              }) ?? {
                value: undefined,
                label: 'Selecione',
              }
            }
            isRequired
            setInvalid={!!errors.cod_interface_carga_pdv}
            iniInicializado={!!errors.cod_interface_carga_pdv}
            onChange={(value: any, isInvalid = true) => {
              setValue('cod_interface_carga_pdv', value);
              changeFormRegraLoja({
                ...formRegraLoja,
                cod_interface_carga_pdv: {
                  ...formRegraLoja.cod_interface_carga_pdv,
                  value,
                  isInvalid: value.value === undefined,
                },
              });
            }}
          />
        </div>
        {!disabled && (
          <div className="com-sm-12 col-md-6">
            <InputText
              value={formRegraLoja.des_path_carga_pdv.value || ''}
              maxLength={500}
              label="Salvar em"
              placeholder=""
              isRequired
              setInvalid={!!errors.des_path_carga_pdv}
              onChange={(value: string, isInvalid = false) => {
                setValue('des_path_carga_pdv', value);
                changeFormRegraLoja({
                  ...formRegraLoja,
                  des_path_carga_pdv: {
                    ...formRegraLoja.des_path_carga_pdv,
                    value,
                    isInvalid: value === '',
                  },
                });
              }}
            />
          </div>
        )}

        {formRegraLoja.tipo_integra_frente.value.label === 'ipdv' && (
          <div className="col-sm-12 col-md-5">
            <InputText
              value={formRegraLoja.des_path_banco_frente.value || ''}
              maxLength={500}
              label="Carga/Pedido PDV Banco a Banco"
              placeholder=""
              isRequired
              setInvalid={!!errors.des_path_banco_frente}
              onChange={(value: string, isInvalid = false) => {
                setValue('des_path_banco_frente', value);
                changeFormRegraLoja({
                  ...formRegraLoja,
                  des_path_banco_frente: {
                    ...formRegraLoja.des_path_banco_frente,
                    value,
                    isInvalid: value === '',
                  },
                });
              }}
            />
          </div>
        )}
        {!disabled && (
          <div className="col-sm-12 col-md-6 mt-3">
            <ToggleDefault
              labelText="Compactar Arquivos na Geração da Carga?"
              setChecked={formRegraLoja.flg_compacta_arq_pdv.value}
              onSwitch={() => {
                setValue(
                  'flg_compacta_arq_pdv',
                  !formRegraLoja.flg_compacta_arq_pdv.value,
                );
                changeFormRegraLoja({
                  ...formRegraLoja,
                  flg_compacta_arq_pdv: {
                    ...formRegraLoja.flg_compacta_arq_pdv,
                    value: !formRegraLoja.flg_compacta_arq_pdv.value,
                  },
                });
              }}
            />
          </div>
        )}
        {!disabled &&
          [0, 3, 4].includes(formRegraLoja.tipo_integra_frente.value.value) && (
            <div className="col-sm-8 col-md-6 mt-3">
              <ToggleDefault
                labelText="Enviar PLU (Posição 1-20 ESTOQUE.TXT)?"
                setChecked={formRegraLoja.flg_enviar_plu_frente.value ?? false}
                onSwitch={() => {
                  setValue(
                    'flg_enviar_plu_frente',
                    !formRegraLoja.flg_enviar_plu_frente.value,
                  );
                  changeFormRegraLoja({
                    ...formRegraLoja,
                    flg_enviar_plu_frente: {
                      ...formRegraLoja.flg_enviar_plu_frente,
                      value: !formRegraLoja.flg_enviar_plu_frente.value,
                    },
                  });
                }}
              />
            </div>
          )}
      </Row> */}
    </>
  );
};

export default Geral;
